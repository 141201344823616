<template>
    <table class="rekap-status">
        <thead>
            <tr>
                <!-- <th>Mateřská škola</th> -->
                <th>Stav žádostí</th>
                <th v-for="(school,index) in schools" :key="index" rowspan="2">{{school.short_name}}</th>
            </tr>
            <!-- <tr>
                <th>Stav žádostí</th>
           </tr> -->
        </thead>
        <tbody>
            <tr v-for="(status_text,status_index) in status" :key="status_index">
                <td>{{ status_text }}</td>
                <td v-for="(school,index) in schools" :key="index">
                    {{ getValue(school.item_key,status_index) }}
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: "RekapitulaceDleStavu",
    props: {
        data: Object,
        schools: Object,
        status: Object,
    },
    setup(props) {


        const getValue = (school_key,status_index) => {
            if(!Object.prototype.hasOwnProperty.call(props.data,school_key)){
                return '';
            }
            if(!Object.prototype.hasOwnProperty.call(props.data[school_key],status_index)){
                return '';
            }
            return props.data[school_key][status_index];
        }

        return {
            getValue
        }
    }
}
</script>

<style scoped>
.rekap-status th{
    vertical-align: top;

}

</style>