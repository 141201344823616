<template>
    <table class="rekap-stari">
        <thead>
            <tr>
                <th>Stáří dětí</th>
                <th v-for="(school,index) in schools" :key="index">{{school.short_name}}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(stari_text,stari_index) in stari" :key="stari_index">
                <td>{{ stari_text }}</td>
                <td v-for="(school,index) in schools" :key="index">
                    {{ getValue(school.item_key,stari_index) }}
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { ref } from 'vue'

export default {
    name: "RekapitulaceDleStari",
    props: {
        data: Object,
        schools: Object
    },
     setup(props) {
        const stari = ref({
            2: "..2",
            3: "3",
            4: "4",
            5: "5",
            6: "6",
            7: "7",
            8: "8..",
            celkem: "CELKEM",
        });

        const getValue = (school_key,stari_index) => {
            if(!Object.prototype.hasOwnProperty.call(props.data,school_key)){
                return '';
            }
            if(!Object.prototype.hasOwnProperty.call(props.data[school_key],stari_index)){
                return '';
            }
            return props.data[school_key][stari_index];
        }

        return {
            stari,
            getValue
        }
    }
}
</script>

<style scoped>

</style>