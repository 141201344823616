<template>
    <div class="rekapitulace">
        <h1 >{{ $t('enrollment.rekapitulace') }}</h1>
        <LoadedContent  :loaded="isLoaded && isLoadedSchools" :error="isError || isErrorSchools">
            <section>
                <header>
                    <h2 >{{ $t('enrollment.rekapitulace_dle_stavu') }}</h2>
                </header>
                
                <RekapitulaceDleStavu :data="data.stav_zadosti" :schools="schools" :status="status" />
                <footer class="pozn">
                    {{ $t('enrollment.rekapitulace_pozn' )}}
                </footer>
            </section>

            <section>
                <header class="header-flex">
                    <h2 >{{ $t('enrollment.rekapitulace_dle_stari') }}</h2>
                    <div >
                        <div class="select"> 
                            <label for="select_by_status">výběr dle stavu žádosti: </label>
                            <select name="select_by_status" id="select_by_status" v-model="select_by_status" @change="selectBy($event)">
                                <option value="">-</option>
                                <option v-for="(status_text,index) in status" :key="index" :value="index">{{ status_text }}</option>
                            </select>
                        </div>
                        <div class="select"> 
                            <label for="select_by_pohlavi">kluci/holky: </label>
                            <select name="select_by_pohlavi" id="select_by_pohlavi" v-model="select_by_pohlavi" @change="selectBy($event)">
                                <option value="">-</option>
                                <option v-for="(pohlavi_text,index) in pohlavi" :key="index" :value="index">{{ pohlavi_text }}</option>
                            </select>
                        </div>
                    </div>                    
                </header>
                
                <RekapitulaceDleStari :data="filteredData.stari_deti" :schools="schools" />
                <footer class="pozn">
                    {{ $t('enrollment.rekapitulace_pozn' )}}
                </footer>
            </section>
            
        </LoadedContent>

    </div>
</template>

<script>
import LoadedContent from '@common/components/base/LoadedContent';
import { computed, provide, ref } from 'vue';
import { useStore } from 'vuex';
import RekapitulaceDleStavu from '@admin/components/enrollment/RekapitulaceDleStavu';
import RekapitulaceDleStari from '@admin/components/enrollment/RekapitulaceDleStari';
import { useI18n } from 'vue-i18n';

export default {
    name: "Rekapitulace",
    components: {
        LoadedContent,
        RekapitulaceDleStavu,
        RekapitulaceDleStari,
    },
    setup(){
        provide("crud_store","application")

        const store = useStore();
        store.dispatch('application/fetchRecapitulation',{});
        store.dispatch('school/fetchItems',{});
        
        const schools = computed(() => store.getters['school/getItems']);
        // getting data
        const data = computed(() => store.getters['application/getRecapitulation']);
        const filteredData = computed(() => store.getters['application/getFilteredRecapitulation']);

        const isLoaded = computed(() => store.getters["application/isLoaded"]);
        
        const isError = computed(() => store.getters["application/isError"]);

        
        const isLoadedSchools = computed(() => store.getters["application/isLoaded"]);
        
        const isErrorSchools = computed(() => store.getters["application/isError"]);

        const select_by_status = ref("");
        const select_by_pohlavi = ref("");
        
        const i18n = useI18n();
        const status = ref({
            0: i18n.t("enrollment.nova_zadost"),
            1: i18n.t("enrollment.zadost_v_rizeni"),
            2: i18n.t("enrollment.dite_prijato"),
            3: i18n.t("enrollment.dite_neprijato"),
        });
        
        const pohlavi = ref({
            10: i18n.t("enrollment.jen_kluci"),
            20: i18n.t("enrollment.jen_holky"),
        });

        const selectBy = () => {
            store.dispatch('application/fetchFilteredRecapitulation',{status: select_by_status.value,pohlavi: select_by_pohlavi.value});
        }

        return {
            data,
            filteredData,
            isLoaded,
            isError,
            schools,
            isLoadedSchools,
            isErrorSchools,
            select_by_status,
            select_by_pohlavi,
            status,
            pohlavi,
            selectBy
        }

    }
}
</script>

<style scoped>
.rekapitulace,.loaded-content{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.loaded-content>section{
    padding: 1rem;
}
.rekapitulace h2{
    font-size: 1.3rem;
}
.rekapitulace ::v-deep(table){
    width: fit-content;
    border-collapse: collapse;
    overflow-y: auto;
    position: relative;
    width: 100%;
    box-shadow: 0 0 10px -1px grey;
}
.rekapitulace ::v-deep(th){
    /* background: #ffd58c; */
    padding: 1rem;
    border-bottom: 1px solid #a08c67;
}
.rekapitulace ::v-deep(tr){
    text-align: center;
}
.rekapitulace ::v-deep(td){
    padding: 0.5rem;
}

.rekapitulace ::v-deep(td:not(:first-child)),.rekapitulace ::v-deep(th:not(:first-child)){
    border-left: 1px solid #a08c67;
}
/* .rekapitulace ::v-deep(tbody tr) {
    background: #fff6e6;;
} */
.rekapitulace ::v-deep(tbody tr:nth-child(odd)) {
    background: #fff6e6;
}

.rekapitulace ::v-deep(tbody tr:nth-child(even)) {
    background: #ffe7bb;
}
.rekapitulace ::v-deep(td:nth-child(1)) {   
    background: rgb(243 205 134 / 27%);
    font-weight: 700;
}

.rekapitulace ::v-deep(.rekap-stari tbody tr:last-child) {
    background: #daf9de;
    font-weight: 700;
}
.pozn{
    font-size: 0.8rem;
    text-align: right;
    padding: 0.5rem;
}
.header-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.select{
    text-align: right;
    margin: 0.5rem;
    font-size: 0.9rem;
}

</style>